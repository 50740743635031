export const AUDIO_LIST = [
  {
    index: 0,
    name: 'Áng Mây Vô Tình - Thương Võ',
    path: '/Áng Mây Vô Tình (New Version) - Thương Võ (128 kbps).mp3'
  },
  {
    index: 1,
    name: 'Chỉ vì quá hy vọng - Hoài Lâm',
    path: '/CHỈ VÌ QUÁ HY VỌNG - HOÀI LÂM _ OFFICIAL MV (128 kbps).mp3'
  },
  {
    index: 2,
    name: 'Cô đơn trên sofa - Hồ Ngọc Hà x Tăng Duy Tân _ Trung Quân',
    path: '/CÔ ĐƠN TRÊN SOFA _ Hồ Ngọc Hà x Tăng Duy Tân _ Trung Quân live cover at Soul of The Forest (128 kbps).mp3'
  },
  {
    index: 3,
    name: 'Hẹn yêu - Minh Vương M4U ft. Thương Võ',
    path: '/HẸN YÊU - MINH VƯƠNG M4U ft. THƯƠNG VÕ _ OFFICIAL MUSIC VIDEO (128 kbps).mp3'
  },
  {
    index: 4,
    name: 'Khúc vương tình - Cần Vinh x Lee Ken',
    path: '/KHÚC VƯƠNG TÌNH __ CẦN VINH x LEE KEN __ OFFICIAL MUSIC VIDEO (128 kbps).mp3'
  },
  {
    index: 5,
    name: 'Mang Tiền Về Cho Mẹ ft. Nguyên Thảo',
    path: '/Mang Tiền Về Cho Mẹ ft. Nguyên Thảo (M_V) (128 kbps).mp3'
  },
  {
    index: 6,
    name: 'Ta chẳng còn ai - Mỹ Tâm',
    path: '/MỸ TÂM  - TA CHẲNG CÒN AI (LIVE) (128 kbps).mp3'
  },
  {
    index: 7,
    name: 'Ngày mai người ta lấy chồng _ Trung Quân x Thành Đạt x Đông Thiên Đức',
    path: '/NGÀY MAI NGƯỜI TA LẤY CHỒNG _ Trung Quân x Thành Đạt x Đông Thiên Đức _ Live Cover at Isle of Art (128 kbps).mp3'
  },
  {
    index: 8,
    name: 'Phương Thanh - Xuân Hạ Thu Đông 39',
    path: '/Phương Thanh - Xuân Hạ Thu Đông 39 _ Official Lyric Video (128 kbps).mp3'
  },
  {
    index: 9,
    name: 'Tất cả không là gì cả - Cao Thái Sơn',
    path: '/TẤT CẢ HOẶC KHÔNG LÀ GÌ CẢ - CAO THÁI SƠN _ OFFICIAL MUSIC VIDEO _ Cuối Cùng Thì Mình Gọi Nhau Là Gì (128 kbps).mp3'
  },
  {
    index: 10,
    name: 'Quen mà xa lạ - Hoài An',
    path: '/QUEN MÀ XA LẠ _ OFFICIAL MV _ TÁI HỢP VỚI NS HOÀI AN (128 kbps).mp3'
  }
]
